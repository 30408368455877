import React from 'react';
import moment from 'moment-timezone'

class PrintRoomGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showRow: []
        }
        this.toggleRow = this.toggleRow.bind(this);
    }

    toggleRow(batchIndex) {
        let showRow = [...this.state.showRow];
        showRow[batchIndex] = !showRow[batchIndex];
        this.setState({
            showRow: showRow
        });
    }

    componentDidMount() {
        if(this.props.urlBatchId !== "" && this.props.textResults.length > 0 && this.state.urlBatchIndex === "") {
            let index = this.props.textResults.findIndex(x => x.PrintBatchID == this.props.urlBatchId);            
            this.toggleRow(index)            
        }
    }

    render() {
        const { showRow } = this.state;
        const findMember = memberId => {
            const patron = this.props.members.find(member => member.MemberID === memberId);
            if (patron) {
                return patron.PatronDisplayName;
            } else {
                return '';
            }
        }
        const headerStyle = {
            color: 'white',
            backgroundColor: '#9e9e9e',
            padding: '10px',
            border: '1px solid #e0e0e0'
        }
        return (<div>
            {this.props.textResults.map((batch, batchIndex) =>
                <div key={batchIndex}>
                    {batch.BatchItems.length > 0 &&
                    <React.Fragment>
                        <div className="flex-row" style={headerStyle}>
                            <div>
                                <button
                                    className={showRow[batchIndex] ? "arrow-up" : "arrow-down"}
                                    onClick={() => this.toggleRow(batchIndex)}>
                                </button>
                                <span>{moment(batch.BatchDate).format('MM/DD/YYYY HH:mm:ss')}</span>
                                <span style={{ marginLeft: '5px' }}>({batch.batchNumber})</span>
                                {batch.BatchDescription &&
                                    <span> - {batch.BatchDescription}</span>
                                }
                                {batch.VendorBatch
                                    ? <span> Vendor</span>
                                    : <span> Bill Room</span>
                                }
                            </div>
                            <div>
                                <span>Total pages: {batch.totalPageCount}</span>
                            </div>
                        </div>
                        {showRow[batchIndex] &&
                            <form>
                                <div className="bill-grid print-bill-grid print-room-bill-grid">
                                    <div className="bill-grid-header">
                                        <input
                                            type="checkbox"
                                            checked={batch.selectAll || ''}
                                            onChange={() => this.props.toggleSelectAll(batchIndex)} />
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>Bill #</span>
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>Document</span>
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>Date</span>
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>Patron</span>
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>Caption</span>
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>Page Count</span>
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>Status</span>
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>Comment</span>
                                    </div>
                                    <div className="bill-grid-header">
                                        <span>View</span>
                                    </div>
                                </div>
                                <div>
                                    {batch.BatchItems.map((text, textIndex) =>
                                        <div key={textIndex} className="bill-grid print-bill-grid print-room-bill-grid">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={text.checked || ''}
                                                    onChange={(e) => this.props.toggleText(batchIndex, textIndex, e)} />
                                            </div>
                                            <div>
                                                <span><a
                                                    target="_blank"
                                                    href={`/bill-details/${this.props.selectedSession.SessionCode}/${text.LegislationNumber}`}>
                                                    {text.LegislationNumber}
                                                </a></span>
                                            </div>
                                            <div>
                                                {text.IsReprint && <div className="small-text message-error">REPRINT</div>}
                                                <span>{text.DocumentCode}</span>
                                            </div>
                                            <div>
                                                <span>{moment(text.VersionDate).format('L')}</span>
                                            </div>
                                            <div>
                                                <span>{findMember(text.MemberID)}</span>
                                            </div>
                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <span
                                                    title={text.LegislationDescription || text.Description}
                                                    style={{ whiteSpace: 'nowrap' }}>
                                                    {text.LegislationDescription || text.Description}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {text.PDFFile && text.PDFFile.length > 0 &&
                                                        <React.Fragment>{text.PDFFile[0].PageCount}</React.Fragment>
                                                    }
                                                </span>
                                            </div>
                                            <div>
                                                <span>{text.PrintDate ? "Complete" : "Incomplete"}</span>
                                            </div>
                                            <div style={text.Comment && text.Comment.length > 12 ? {overflowX: "scroll"} : {}}>
                                                <span style={{whiteSpace: "nowrap"}}>{text.Comment}</span>                                                                                                    
                                            </div>
                                            <div>
                                                <div className="inner-grid three">
                                                    {text.PDFFile && text.PDFFile.length > 0 ?
                                                        <a target="_blank" href={text.PDFFile[0].FileURL}><span className="icon pdf"></span></a>
                                                        :
                                                        <br />
                                                    }
                                                    <div>
                                                        <span
                                                            onClick={(e) => this.props.togglePortal(this.props.showPortal, e, text.LegislationTextID)}
                                                            className="icon paper"></span>
                                                    </div>
                                                    <div>
                                                        {text.IsPublic &&
                                                            <i className="icon book"/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        }
                    </React.Fragment>
                    }
                </div>
            )}
        </div>);
    }
}

export default PrintRoomGrid;