import React from 'react';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { patronActionCreators } from '../../../stores/lis-patron-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import moment from 'moment-timezone';
import queryString from 'query-string';
import '../../../stylesheets/lis-public-view/public-view.css';
import EventControls from './event-controls';
import BillCategories from '../../../lis-shared/lis-search/lis-search-categories';
import AdvancedSearchBox from '../../../lis-shared/lis-search/lis-advanced-search-box';
import SearchSelections from '../../../lis-shared/lis-search/lis-search-selections';
import BillHistoryForm from '../../../lis-public/components/lis-bill-details/lis-history-form';
import LegislationWatchlists from '../../../lis-shared/lis-search/lis-legislation-watchlists';
import CollectionControls from '../../../lis-shared/lis-search/lis-collection-controls'
import { cancelRequest } from '../../../services/request.service';
import { collectionActionCreators } from '../../../stores/lis-collection-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { minutesActionCreators } from '../../../stores/lis-minutes-store';
import BillInfoComponent from '../../../lis-shared/lis-search/lis-bill-info';

const BILL_COLLECTION_AUTHOR = "LegislationCollections";

class BillManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCategories: false,
            billList: [],
            billStatusCategoryList: [],
            billStatusCategoryListIsLoading: true,
            billEventOptions: [],
            selectedBillEvent: '',
            showOverlay: false,
            selectedBill: '',
            selectedKeywords: '',
            selectedBillAction: '',
            billEventStartDate: null,
            billEventEndDate: null,
            selectedBillSession: '',
            selectedBillType: '',
            previousSearch: '',
            searchType: { value: 'all', label: 'All' },
            selectedChamber: '',
            patronList: [],
            patronRoles: [],
            selectedBillNumbers: '',
            selectedBillRangeBeginning: '',
            selectedBillRangeEnd: '',
            selectedChapterNumber: '',
            filteredPatronList: [],
            selectedPatron: '',
            selectedPatronType: [],
            selectedBillStatusCategory: '',
            sessionOptions: [],
            billStatusStartDate: null,
            billStatusEndDate: null,
            committeeList: [],
            fullCommitteeList: [],
            groupedCommitteeList: [],
            filteredCommitteeList: [],
            selectedCommittee: '',
            selectedSubcommittee: '',
            subcommitteeList: [],
            subjectList: [],
            selectedSubject: '',
            introDateList: [],
            collections: [],
            selectedCollection: '',
            selectedCollectionIndex: -1,
            checkedBills: [],
            lastCheckedBill: -1,
            selectAllBills: false,
            billVersionList: [],
            selectedBillVersion: 'All',
            billSummaryList: [],
            selectedSummaryVersion: 'All',
            selectedLocation: 'Caption',
            actionTypes: [],
            otherBillEventOptions: [],
            currentStatus: true,
            legislationIDs: [],
            showSidebar: true,
            hideAllEvents: [true],
            height: 1,
            showModal: false,
            introductionDate: null,
            excludeFailed: false,
            showUpdateSearchButton: false
        };
        this.searchOnEnterPress = this.searchOnEnterPress.bind(this);
        this.toggleGroup = this.toggleGroup.bind(this);
        this.toggleShowCategories = this.toggleShowCategories.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.getBillsResults = this.getBillsResults.bind(this);
        this.chamberChange = this.chamberChange.bind(this);
        this.sessionChange = this.sessionChange.bind(this);
        this.committeeChange = this.committeeChange.bind(this);
        this.handlePatronChange = this.handlePatronChange.bind(this);
        this.handlePatronTypeChange = this.handlePatronTypeChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectionClear = this.handleSelectionClear.bind(this);
        this.handleMultiSelectionClear = this.handleMultiSelectionClear.bind(this);
        this.getCollections = this.getCollections.bind(this);
        this.selectCollection = this.selectCollection.bind(this);
        this.handleBillCheckbox = this.handleBillCheckbox.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.removeEvent = this.removeEvent.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.getOtherBillEventOptions = this.getOtherBillEventOptions.bind(this);
        this.handleCurrentStatusChange = this.handleCurrentStatusChange.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.toggleShowData = this.toggleShowData.bind(this);
        this.togglehideAllEvents = this.togglehideAllEvents.bind(this);
        this.toggleAllBills = this.toggleAllBills.bind(this);
        this.handleEditedBills = this.handleEditedBills.bind(this);
        this.getSessions = this.getSessions.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleExcludeFailedChange = this.handleExcludeFailedChange.bind(this);
        this.toggleExpandAllLegislation = this.toggleExpandAllLegislation.bind(this);

        // The div that sorrounds the page is in this component but the function that needs to run to be able to make a search is in the child component.
        // This ref is used to call the child component's function.
        this.searchBox = React.createRef();
    }

    componentDidMount() {
        // IF A SEARCH QUERY EXISTS
        let queryObj = '';
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.q) {
            const base64 = parsed.q;
            const decodedSearch = window.atob(base64);
            queryObj = JSON.parse(decodedSearch);
            this.setState(state => ({
                ...state,
                ...queryObj
            }), () => {
                //If we haven't arrived here to edit history then go ahead with the search
                if (queryObj.calendarCategoryTypeID) {
                    this.setState({
                        calendarHeader: {
                            Name: 'Calendar on ' + moment(queryObj.calendarDate).format("MM/DD/YYYY"),
                            Description: queryObj.calendarCategoryDescription
                        }
                    })
                    this.getSessions(queryObj);
                } else {
                    this.getSessions();
                }
            });
        } else {
            this.getSessions();
        }
        this.getBillStatusCategoryOptions();
        this.getBillVersionOptions();
        this.getBillSummaryOptions();
        this.getBillEventOptions(queryObj.calendarCategoryTypeID);
        this.getPatronRoles();

        this.props.actions.getActionTypes()
            .then(() => {
                let actionTypes = [...this.props.minutes.actionTypes];
                actionTypes.forEach(type => {
                    type.label = type.ActionReferenceType;
                    type.value = type.ActionReferenceTypeID;
                })
                this.setState({
                    actionTypes: actionTypes
                });
            });
    }

    getSessions(queryObj) {
        let sessions = ''
        if (!this.state.sessionOptions.length) {
            sessions = this.props.actions.getSessionList()
                .then(() => {
                    this.setState({
                        sessionOptions: this.props.session.sessionList
                    })
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err)
                });
        } else {
            sessions = () => Promise.resolve();
        }

        Promise.all([sessions]).then(() => {
            let selectedSession = '';
            let defaultSession = '';
            this.state.sessionOptions.forEach(session => {
                if (queryObj && session.SessionID === queryObj.selectedSession) {
                    selectedSession = session;
                    this.props.actions.changeSession(session.SessionCode);
                } else if (session.SessionCode === this.props.nav.session && !selectedSession) {
                    selectedSession = session;
                }
                if (session.IsDefault) {
                    defaultSession = session;
                }
            });
            //Only set the session to default if the queryObj doesn't have a sessionID and the the session selector doesn't have a sessionID
            if (!selectedSession) {
                selectedSession = defaultSession;
            }

            if (selectedSession) {
                const sessionStartObj = selectedSession.SessionEvents && selectedSession.SessionEvents.length && selectedSession.SessionEvents.find(date => date.DisplayName === "Session Start");
                const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate).format("MM/DD/YYYY") : '';
                const dateParam = 'effectiveDate=' + encodeURIComponent(startDate);
                const sessionParam = 'sessionID=' + selectedSession.SessionID;
                this.getPatrons(sessionParam);
                this.getSubjects(sessionParam);
                this.getCommittees(dateParam);
                this.getIntroDates(sessionParam)

                this.setState({ selectedSession }, () => {
                    if (this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR)) {
                        this.getCollections("?SessionID=" + this.state.selectedSession.SessionID);
                    }
                    if (queryObj && !queryObj.sessionOnly) {
                        this.handleSearch()
                    } else {
                        this.setState({ showCategories: true })
                    }

                    document.querySelector('body').addEventListener('keydown', this.searchOnEnterPress);
                })
            } else {
                this.setState({ failedToGetSessions: true })
            }
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.executeSearch();
        }
        if (this.props.nav.session !== prevProps.nav.session) {
            if (!isNaN(parseInt(this.props.nav.session))) {
                this.sessionChange(this.props.nav.session);
            }
        }

        //get collections if user logs in/logged in user changes
        if (this.props.login.userProfile.email && this.props.login.userProfile.email !== prevProps.login.userProfile.email) {
            if (this.state.selectedSession.SessionID && this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR)) {
                this.getCollections("?SessionID=" + this.state.selectedSession.SessionID);
            } else if (this.state.collections && this.state.collections.length) {
                this.setState({ collections: [] })
            }
        }

        if (this.state.billList !== prevState.billList || this.state.showCategories !== prevState.showCategories) {
            if (window.innerWidth > 900) { //breaks mobile otherwise, and the sidebar toggle isn't shown on mobile anyways
                let height = document.getElementById('results-div');
                if (height && (height.scrollHeight / 540) !== this.state.height) {
                    this.setState({
                        height: (height.scrollHeight / 540) >= 1 ? (height.scrollHeight / 540) : 1
                    })
                } else {
                    this.setState({
                        height: 1
                    })
                }
            }
        }
    }

    executeSearch() {
        let queryObj = '';
        const parsed = queryString.parse(this.props.location.search, { decode: false });
        if (parsed.q) {
            const base64 = parsed.q;
            const decodedSearch = window.atob(base64);
            queryObj = JSON.parse(decodedSearch);
            //Remove any existing entries in the form and replace it with the content of the url
            this.clearForm();
            this.setState(state => ({
                ...state,
                ...queryObj
            }), () => {
                //If a member is already selected and there are no patron types selected then select them all by default
                if (this.state.selectedPatron && this.state.selectedPatronType.length === 0) {
                    const patronRoles = [];
                    this.state.patronRoles.forEach(role => patronRoles.push(role.PatronTypeID));
                    this.setState({
                        selectedPatronType: patronRoles
                    });
                }
                //If a committee is already selected and there is no legislation status category then select 'In Committee' as the status
                if (this.state.selectedCommittee && !this.state.selectedBillStatusCategory) {
                    const selectedBillStatusCategory = this.state.billStatusCategoryList.find(status => status.Name === 'In Committee');
                    this.setState({
                        selectedBillStatusCategory: selectedBillStatusCategory ? selectedBillStatusCategory.LegislationCategoryID : ''
                    });
                }
                this.getSessions(queryObj);
            });
        } else if (!parsed.collection) {
            //this means the user hit the back button from a query and there are no longer any params in the url,
            //and the user should be back at the base search page with categories
            this.clearForm(true);
        }
    }

    componentWillUnmount() {
        document.querySelector('body').removeEventListener('keydown', this.searchOnEnterPress);
        cancelRequest();
    }

    searchOnEnterPress(event) {
        if (event.key === 'Enter' && !this.state.isFetching) {
            //Pressing enter while on a react-select will select an option. A search should not happen because of that.
            //Also do not submit a search if they are entering a value in the quick-search input at the top right which sends the user to the public search
            //otherwise, if they are on the categories page in bill mgmt, search using the quick-search, and then hit back button from the public bill search results, it will send them to the results page in bill mgmt rather than back to the categories page
            if (!event.target.id.includes('react-select') && !event.target.className.includes('note-area') && !event.target.className.includes('event-date-picker') && event.target.id !== "quick-search") {
                if (!this.state.selectedChamber && !this.state.selectedBillEvent && !this.state.selectedBillType && !this.state.selectedCommittee && !this.state.selectedSubcommittee && !this.state.selectedPatron && !this.state.selectedSubject && !this.state.selectedKeywords && !this.state.selectedBillNumbers && !this.state.selectedBillRangeBeginning && !this.state.selectedBillRangeEnd && !this.state.selectedSubject && !this.state.selectedChapterNumber && !this.state.selectedBillStatusCategory) {
                    //there are not any search parameters provided, don't execute a search
                    return;
                }
                this.searchBox.current.handleSubmit();
            }
        }
    }

    toggleShowCategories(val) {
        this.setState({
            showCategories: val
        });
    }

    toggleGroup(groupName) {
        this.setState(state => ({
            [groupName]: !state[groupName]
        }));
    }

    clearForm(clearUrl, clearBillData) {
        this.setState({
            selectedBillEvent: '',
            selectedBill: '',
            selectedKeywords: '',
            selectedBillAction: '',
            billEventStartDate: null,
            billEventEndDate: null,
            selectedBillSession: '',
            selectedBillType: '',
            selectedBillNumbers: '',
            selectedBillRangeBeginning: '',
            selectedBillRangeEnd: '',
            selectedChapterNumber: '',
            subcommitteeList: [],
            selectedPatron: '',
            selectedPatronType: '',
            selectedChamber: '',
            selectedBillStatusCategory: '',
            billStatusStartDate: null,
            billStatusEndDate: null,
            selectedCommittee: '',
            selectedSubcommittee: '',
            selectedSubject: '',
            isPending: null,
            mostFrequent: null,
            allLegislation: null,
            selectedBillVersion: 'All',
            selectedSummaryVersion: 'All',
            selectedLocation: 'Caption',
            displaySelectedLocation: "Caption",
            introductionDate: null
        }, () => {
            if (clearUrl) {
                this.props.history.push('/bill-management');
                this.setState({ billList: [], showCategories: true })
            }
            if (clearBillData) {
                this.setState({
                    billList: []
                })
            }
        });

    }

    handleSubmit(queryObj) {
        let encodedSearch = window.btoa(JSON.stringify(queryObj));
        //if it is the same search (i.e. same parameters) as the current one, updating the url won't work since it will be the same, just execute it manually
        const parsed = queryString.parse(this.props.location.search, { decode: false });
        if (parsed.q && parsed.q === encodedSearch) {
            this.executeSearch();
        } else {
            this.props.history.push('/bill-management?q=' + encodedSearch + (this.state.selectedCollection ? '&collection=' + this.state.selectedCollection.WatchListID : ''));
        }
    }

    handleSearch() {
        if (this.state.failedToGetSessions) { return; }
        //Format the selected options to be sent to the API
        //Add bill range to list of bills
        let selectedBillNumbers = this.state.selectedBillNumbers.trim();
        if (this.state.selectedBillRangeBeginning && this.state.selectedBillRangeEnd) {
            selectedBillNumbers = selectedBillNumbers ? selectedBillNumbers + ',' : selectedBillNumbers;
            selectedBillNumbers += this.state.selectedBillRangeBeginning + '-' + this.state.selectedBillRangeEnd;
        }

        let keywordVersion = null;
        switch (this.state.selectedLocation) {
            case ('Bill Text'):
                if (this.state.selectedBillVersion !== 'All') {
                    keywordVersion = { KeywordLegislationVersionID: this.state.selectedBillVersion }
                }
                break;
            case ('Summary'):
                if (this.state.selectedSummaryVersion !== 'All') {
                    keywordVersion = { KeywordSummaryVersionID: this.state.selectedSummaryVersion }
                }
                break;
        }

        // Build the param array with param names as the keys
        const params = {
            ChamberCode: this.state.selectedChamber ? this.state.selectedChamber.value : null,
            CommitteeID: this.state.selectedSubcommittee || this.state.selectedCommittee || null,
            PatronTypes: this.state.selectedPatronType || null,
            LegislationNumbers: selectedBillNumbers ? [{ LegislationNumber: selectedBillNumbers.replaceAll(" ", "") }] : null,
            ChapterNumber: this.state.selectedChapterNumber,
            SessionID: this.state.selectedSession.SessionID,
            StartDate: this.state.billStatusStartDate ? moment(this.state.billStatusStartDate).format('MM/DD/YYYY') : null,
            EndDate: this.state.billStatusEndDate ? moment(this.state.billStatusEndDate).format() : null,
            KeywordExpression: this.state.selectedKeywords || null,
            KeywordLocation: this.state.selectedLocation || null,
            ...keywordVersion,
            MemberID: this.state.selectedPatron,
            SubjectIndexID: this.state.selectedSubject,
            LegislationCategoryID: this.state.selectedBillStatusCategory,
            LegislationEventTypeID: this.state.selectedBillEvent,
            EventStartDate: this.state.billEventStartDate ? moment(this.state.billEventStartDate).format("MM/DD/YYYY") : null,
            EventEndDate: this.state.billEventEndDate ? moment(this.state.billEventEndDate).format("MM/DD/YYYY") : null,
            IsPending: this.state.isPending || null,
            MostFrequent: this.state.mostFrequent || null,
            AllLegislation: this.state.allLegislation || null,
            CurrentStatus: this.state.currentStatus,
            ExcludeFailed: this.state.excludeFailed,
            LegislationIDs: this.state.legislationIDs,
            IntroductionDate: this.state.introductionDate
        }
        //Open the fieldset if one of the form inputs is filled out
        if (params.LegislationNumbers) {
            this.setState({
                billFieldset: true
            });
        }
        if (params.KeywordExpression) {
            this.setState({
                keywordFieldset: true
            });
        }
        if (params.LegislationCategoryID || params.ChamberCode) {
            this.setState({
                statusFieldset: true
            });
        }
        if (params.EventStartDate || params.EventEndDate) {
            this.setState({
                activityFieldset: true
            });
        }
        if (params.MemberID) {
            this.setState({
                membersFieldset: true
            });
        }
        if (params.CommitteeID) {
            this.setState({
                committeesFieldset: true
            });
        }
        if (params.SubjectIndexID) {
            this.setState({
                subjectFieldset: true
            });
        }

        this.setState({
            searchMade: true,
            displaySelectedLocation: this.state.selectedLocation,
            showUpdateSearchButton: false
        });

        this.getBillsResults(params);
    }

    getBillsResults(params) {

        //if the user searches hb1-hb4 and expands all of these bills to see further info, and then search hb1-hb5, leave open their previously expanded bills for them;
        //however, if the existing search (if there is one, i.e. this new one is not the first search they're making) is a bill text keyword search, which automatically expands all of them in order to show bill text matches,
        //then close these in the new search results as there's a good chance they don't care for the expanded info regarding patrons/summary/etc
        const closeBillInfo = this.state.params?.KeywordLocation === "Bill Text" && this.state.params?.KeywordExpression && (params?.KeywordLocation !== "Bill Text" || !params?.KeywordExpression);

        this.setState({
            isFetching: true,
            showCategories: false
        });

        window.scrollTo(0, 0);

        let getResultsPromise;
        if (params.MostFrequent) {
            getResultsPromise = this.props.actions.getMostFrequentBillList("?sessionID=" + params.SessionID + "&excludeFailed=" + params.ExcludeFailed);
        } else {
            getResultsPromise = this.props.actions.getBillList(params)
        }

        getResultsPromise.then(() => {
            let billList = !params.MostFrequent ? [...this.props.bills.billList] : [...this.props.bills.mostFrequentBillList];
            let legislationIDs = "";

            //Can't manage bills that are outside of the selected session
            billList = billList.filter(x => x.SessionID === this.state.selectedSession.SessionID);
            //Filter to only one result per bill per session since DB sometimes returns duplicates which messes up pagination
            billList = billList.filter((item, pos) => billList.findIndex(i => i.LegislationNumber === item.LegislationNumber && i.SessionID === item.SessionID) === pos);
            //If coming from the calendar, sort according to calendar order which is preserved in the query param
            if (this.state.calendarCategoryTypeID && this.state.legislationIDs?.length) {
                billList.sort((a, b) => this.state.legislationIDs.findIndex(l => l.LegislationID === a.LegislationID) - this.state.legislationIDs.findIndex(l => l.LegislationID === b.LegislationID))
            }
            let hideAllEvents = []
            billList.forEach((bill, i) => {
                legislationIDs = legislationIDs + "legislationIDs=" + bill.LegislationID;
                legislationIDs = i + 1 !== this.props.bills.billList.length ? legislationIDs + "&" : legislationIDs;
                // Remove all references to any previous organizedEvents, and set the hideAllEvents for this specific bill to true
                hideAllEvents.push(true)
                this.setState({
                    [`organizedEvents${i}`]: '',
                    hideAllEvents
                })
            });

            if (legislationIDs) {
                this.props.actions.getBillHistoryByBillID(`?${legislationIDs}`, true).then(() => {
                    this.props.actions.getBillVersionsByBillID(`?${legislationIDs}`).then(() => {
                        billList.forEach((bill, i) => {
                            bill.historyList = this.props.bills.billHistory.filter(x => x.LegislationID === bill.LegislationID);
                            bill.versionsList = this.props.bills.billVersion.filter(x => x.LegislationID === bill.LegislationID).map(x => ({
                                ...x,
                                label: x.DocumentCode,
                                value: x.LegislationTextID
                            }));
                        })
                    }).then(() => {
                        this.setState({
                            billList: billList,
                            checkedBills: [],
                            lastCheckedBill: -1,
                            isFetching: false
                        }, () => {
                            if (closeBillInfo)
                                [...this.state.billList].forEach(b => this.toggleShowData(b.LegislationID, false))
                        });
                    })
                });
            } else {
                this.setState({
                    billList: [],
                    checkedBills: [],
                    lastCheckedBill: -1,
                    isFetching: false
                })
            }
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            this.setState({
                isFetching: false
            });
        })
    }

    getBillStatusCategoryOptions() {
        // Get Bill Status references for bill status typeahead
        this.props.actions.getBillStatusCategoryReferences().then(() => {
            let billStatusReferences = [...this.props.bills.billStatusCategoryReferences];
            billStatusReferences.forEach(ref => {
                ref.label = ref.Name
                ref.value = ref.LegislationCategoryID
            });
            if (this.state.selectedCommittee && !this.state.selectedBillStatusCategory) {
                const selectedBillStatusCategory = billStatusReferences.find(status => status.Name === 'In Committee');
                this.setState({
                    selectedBillStatusCategory: selectedBillStatusCategory ? selectedBillStatusCategory.LegislationCategoryID : ''
                });
            }
            this.setState({
                billStatusCategoryList: billStatusReferences,
                billStatusCategoryListIsLoading: false
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
            this.setState({ billStatusCategoryListIsLoading: false })
        });
    }

    getBillVersionOptions() {
        this.props.actions.getBillVersionRef().then(() => {
            let topOfList = [{ Name: 'All Versions', LegislationVersionID: 'All' }];
            let billVersionReferences = topOfList.concat(this.props.bills.billVersionRef);
            billVersionReferences.forEach(ref => {
                ref.label = ref.Name
                ref.value = ref.LegislationVersionID
            });
            this.setState({
                billVersionList: billVersionReferences,
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    getBillSummaryOptions() {
        this.props.actions.getBillSummaryRef().then(() => {
            let topOfList = [{ Name: 'All Summary Versions', SummaryVersionID: 'All' }];
            let billSummaryReferences = topOfList.concat(this.props.bills.billSummaryRef);
            billSummaryReferences.forEach(ref => {
                ref.label = ref.Name
                ref.value = ref.SummaryVersionID
            });
            this.setState({
                billSummaryList: billSummaryReferences,
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    getBillEventOptions(calendarCategoryTypeID) {
        if (calendarCategoryTypeID) {
            this.props.actions.getBillEventReferences("?calendarCategoryTypeID=" + calendarCategoryTypeID)
                .then(() => {
                    let billEventReferences = [...this.props.bills.billEventRef];
                    billEventReferences.forEach(ref => {
                        delete ref.LegislationChamberCode;
                        ref.label = ref.LegislationDescription
                        ref.value = `${ref.EventCode}-${ref.IsPassed}`
                    });
                    billEventReferences = this.filterBillEventReferences(billEventReferences);

                    billEventReferences.push({
                        label: "Other",
                        value: "Other"
                    })
                    this.setState({
                        billEventOptions: billEventReferences
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err)
                });
        } else {
            this.props.actions.getBillEvents("").then(() => {
                let billEventReferences = [...this.props.bills.billEvents];
                billEventReferences.forEach(ref => {
                    delete ref.LegislationChamberCode;
                    ref.label = ref.LegislationDescription
                    ref.value = `${ref.EventCode}-${ref.IsPassed}`
                });
                billEventReferences = this.filterBillEventReferences(billEventReferences);

                this.setState({
                    billEventOptions: billEventReferences
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
        }
    }

    getOtherBillEventOptions() {
        this.props.actions.getBillEvents("").then(() => {
            let billEventReferences = [...this.props.bills.billEvents];
            billEventReferences.forEach(ref => {
                ref.label = ref.LegislationDescription
                ref.value = `${ref.EventCode}-${ref.IsPassed}`
            });
            billEventReferences = this.filterBillEventReferences(billEventReferences);
            this.setState({
                otherBillEventOptions: billEventReferences
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    filterBillEventReferences(billEventReferences) {
        return billEventReferences.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.EventCode === value.EventCode && JSON.stringify(t.ActionReferences) === JSON.stringify(value.ActionReferences) && t.IsPassed === value.IsPassed
            ))
        )
    }

    getPatrons(sessionParam) {
        // Get Patrons list for typeahead
        this.props.actions.getMemberList(sessionParam).then(() => {
            let memberList = [...this.props.members.memberList];
            memberList.forEach(patron => {
                patron.label = patron.ListDisplayName + ' ' + '(' + patron.ChamberCode + ')';
                patron.value = patron.MemberID;
            });
            const member = memberList.find(member => member.MemberID === this.state.selectedPatron);
            let selectedPatronType = [];
            if (member && this.state.selectedPatronType.length === 0) {
                this.state.patronRoles.forEach(role => selectedPatronType.push(role.PatronTypeID));
                this.setState({
                    selectedPatronType: selectedPatronType
                });
            }
            this.setState({
                patronList: memberList,
                filteredPatronList: memberList,
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    getSubjects(sessionParam) {
        this.props.actions.getSubjectList('?' + sessionParam)
            .then(() => {
                this.setState({
                    subjectList: this.props.bills.subjectList
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    getPatronRoles() {
        this.props.actions.getPatronRoles()
            .then(() => {
                const patronRoles = [...this.props.patrons.patronRoles];
                patronRoles.forEach(role => {
                    role.label = role.Name;
                    role.value = role.PatronTypeID;
                });
                this.setState({
                    patronRoles: patronRoles,
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    getCommittees(sessionParam) {
        this.props.actions.getCommitteeList(sessionParam + "&includeSubCommittees=true").then(() => {
            let fullCommitteeList = this.props.committee.committeeList.filter(c => !c.ParentCommitteeID);
            fullCommitteeList.forEach(committee => {
                committee.label = committee.Name + ' ' + '(' + committee.ChamberCode + ')';
                committee.value = committee.CommitteeID;
            });
            let committeeList = fullCommitteeList.filter(c => !c.ParentCommitteeID);
            let groupedCommitteeList = [{ "label": "House Committees", "ChamberCode": "H", "options": committeeList.filter(c => c.ChamberCode === "H") }, { "label": "Senate Committees", "ChamberCode": "S", "options": committeeList.filter(c => c.ChamberCode === "S") }]
            this.setState({
                fullCommitteeList: fullCommitteeList,
                committeeList: committeeList,
                groupedCommitteeList: groupedCommitteeList,
                filteredCommitteeList: groupedCommitteeList
            });
            //If a committee is already selected then that committee's subcommittees need to be retrieved
            if (this.state.selectedCommittee) {
                this.getSubcommittees(this.state.selectedCommittee);
            }
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    getSubcommittees(id) {
        this.props.actions.getCommitteeList("parentCommitteeID=" + id)
            .then(() => {
                let committeeList = [...this.props.committee.committeeList];
                committeeList.forEach(committee => {
                    committee.label = '(' + committee.ChamberCode + ') ' + committee.Name;
                    committee.value = committee.CommitteeID;
                });
                this.setState({
                    subcommitteeList: committeeList
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    getIntroDates(sessionParam) {
        this.props.actions.getIntroDateList('?' + sessionParam)
            .then(() => {
                let datesList = [];
                this.props.bills.introDateList.forEach(date => datesList.push(moment(date.IntroductionDate)));
                this.setState({
                    introDateList: datesList
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    sessionChange(code) {
        const selectedSession = this.state.sessionOptions.find(session => session.SessionCode === code);
        if (!selectedSession) {
            return;
        }
        const sessionParam = 'sessionID=' + selectedSession.SessionID;
        this.getPatrons(sessionParam);
        this.getSubjects(sessionParam);
        this.getCommittees(sessionParam);
        this.getIntroDates(sessionParam);
        this.getCollections("?SessionID=" + selectedSession.SessionID);

        const parsed = queryString.parse(this.props.location.search, { decode: false });
        if (parsed.q) {
            const base64 = parsed.q;
            const decodedSearch = window.atob(base64);
            let queryObj = JSON.parse(decodedSearch);
            if (queryObj.selectedSession) {
                queryObj.selectedSession = selectedSession.SessionID;
                this.handleSubmit(queryObj);
                return;
            }
        } else {
            this.setState({ currentStatus: selectedSession.IsActive })
        }

        if (this.state.selectedBillStatusCategory) {
            this.setState({
                selectedPatron: '',
                selectedPatronType: '',
                selectedCommittee: '',
                selectedSubcommittee: '',
                selectedSubject: '',
                selectedSession: selectedSession
            }, () => {
                this.handleSearch();
            });
        } else {
            this.setState({
                selectedPatron: '',
                selectedPatronType: '',
                selectedCommittee: '',
                selectedSubcommittee: '',
                selectedSubject: '',
                selectedSession: selectedSession
            }, () => {
                const parsed = queryString.parse(this.props.location.search, { decode: false });
                if (parsed.q) {
                    this.handleSearch();
                }
            });
        }
    }

    chamberChange(value) {
        this.setState({
            selectedChamber: value
        });
        if (value) {
            const patronList = [...this.state.patronList];
            const committeeList = [...this.state.groupedCommitteeList];
            //Remove the patrons from the patronList that do not fit the current selected chamber
            //Remove the committees from the committee list that do not fit the current selected chamber
            let filteredPatronList = patronList.filter(patron => patron.ChamberCode === value.value);
            let filteredCommitteeList = committeeList.filter(committee => committee.ChamberCode === value.value);
            this.setState({
                filteredPatronList: filteredPatronList,
                filteredCommitteeList: filteredCommitteeList,
            });
        } else {
            // 'all' has been selected so show all patrons/committees
            this.setState({
                filteredPatronList: this.state.patronList,
                filteredCommitteeList: this.state.groupedCommitteeList,
            });
        }
        // Reset all the values that are chamber specific
        this.setState({
            selectedPatron: '',
            selectedPatronType: '',
            selectedCommittee: '',
            selectedSubcommittee: ''
        });
    }

    committeeChange(opt) {
        this.setState({
            selectedCommittee: opt ? opt.value : null,
            selectedSubcommittee: null
        });
        if (opt) {
            if (!this.state.selectedBillStatusCategory) {
                const selectedBillStatusCategory = this.state.billStatusCategoryList.find(status => status.Name === 'In Committee');
                this.setState({
                    selectedBillStatusCategory: selectedBillStatusCategory ? selectedBillStatusCategory.LegislationCategoryID : ''
                });
            }
            this.getSubcommittees(opt.CommitteeID)
        };
    }

    handlePatronChange(opt) {
        let patronTypes = [];
        if (opt) {
            this.state.patronRoles.forEach(role => patronTypes.push(role.PatronTypeID));
        }
        this.setState({
            selectedPatron: opt ? opt.value : null,
            selectedPatronType: patronTypes
        });
    }

    handlePatronTypeChange(options) {
        let values = [];
        if (options) {
            options.forEach(opt => values.push(opt.value));
        }
        this.setState({
            selectedPatronType: values
        });
    }

    handleSelectChange = (opt, name) => {
        this.setState({
            [name]: opt ? opt.value : null
        });
        if (name === 'selectedBillStatusCategory') {
            if (opt === null || (opt && [2, 3, 4, 6, 7, 11, 22, 16, 17, 21, 27].includes(opt.LegislationCategoryID))) {
                this.setState({
                    currentStatus: true,
                    billStatusStartDate: null,
                    billStatusEndDate: null
                });
            } else if ([1, 5, 23, 24, 18, 26, 29, 8, 9, 10, 19, 14, 20, 25, 13, 15, 28].includes(opt.LegislationCategoryID)) {
                this.setState({
                    currentStatus: false
                });
            }
        }
    }

    handleDateChange = (val, name) => {
        this.setState({
            [name]: val
        });
    }

    handleTextChange = (evt, name) => {
        let value = evt.target.value;
        if (['selectedChapterNumber', 'selectedBillRangeBeginning', 'selectedBillRangeEnd'].includes(name)) {
            value = value.replace(/[^a-zA-Z0-9]/, "");
        }
        this.setState({ [name]: value });
    }

    handleSelectionClear(key) {
        //Clearing some form inputs changes the value of other form inputs. Handle them here.
        this.setState({ showUpdateSearchButton: true }, () => {
            switch (key) {
                case "selectedBillStatusCategory":
                    this.handleSelectChange(null, "selectedBillStatusCategory");
                    break;
                case "selectedPatron":
                    this.handlePatronChange(null);
                    break;
                case "selectedCommittee":
                    this.committeeChange(null);
                    break;
                case "selectedChamber":
                    this.chamberChange(null);
                    break;
                default:
                    this.setState({
                        [key]: ''
                    });
            }

            if (!localStorage.getItem('searchModalDismissed')) {
                this.toggleModal();
                localStorage.setItem('searchModalDismissed', true);
            }
        })
    }

    handleMultiSelectionClear(key, index) {
        let value = [...this.state[key]];
        value.splice(index, 1);
        this.setState({
            [key]: value,
            showUpdateSearchButton: true
        })
    }

    handleCurrentStatusChange() {
        this.setState({
            currentStatus: !this.state.currentStatus
        }, () => {
            if (this.state.currentStatus) {
                this.setState({
                    billStatusStartDate: null,
                    billStatusEndDate: null
                })
            }
        })
    }

    getCollections(params) {
        this.setState({
            collectionIsLoading: true
        });
        this.props.actions.getCollections(params)
            .then(() => {
                const parsed = queryString.parse(this.props.location.search);
                const collectionIndex = this.props.collection.collections.findIndex(coll => coll.WatchListID === parseInt(parsed.collection))
                this.setState({
                    collections: this.props.collection.collections,
                    collectionIsLoading: false,
                }, () => {
                    if (collectionIndex !== -1) {
                        this.selectCollection(collectionIndex)
                    }
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return
                }
                this.setState({
                    collectionIsLoading: false
                });
            });
    }

    selectCollection(collectionIndex) {
        if (collectionIndex === this.state.selectedCollectionIndex) {
            this.setState({
                selectedCollectionIndex: -1,
                selectedCollection: ''
            })
        } else {
            if (this.state.collections[collectionIndex].IsPaid && !Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) && !Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All"))) {
                alert("The watchlist you are trying to access is a paid watchlist. As a free user, you are no longer able to access this watchlist.");
                return;
            }

            let legNumbers = '';
            this.state.collections[collectionIndex].WatchListLegislation.filter(leg => !leg.DeletionDate).forEach((leg, legIndex) => {
                if (legIndex !== 0) {
                    legNumbers += ', ';
                }
                legNumbers += leg.LegislationNumber;
            });
            // Don't make a search lacking any legislation
            if (legNumbers) {
                let queryObj = {
                    selectedBillNumbers: legNumbers,
                    selectedSession: this.state.selectedSession.SessionID
                };
                this.handleSubmit(queryObj);
            } else {
                // This watchlist does not have any legislation. Clear the form and clear the results.
                this.clearForm(true);
                this.setState({
                    billList: []
                });
            }
            this.setState({
                selectedCollectionIndex: collectionIndex,
                selectedCollection: this.state.collections[collectionIndex]
            })
        }
    }

    selectCollection(collectionIndex, returningFromSearch) {
        if (collectionIndex === -1) {
            this.setState({
                selectedCollectionIndex: -1,
                selectedCollection: '',
                billList: [],
                searchMade: true
            })
            this.props.history.push('/bill-management');
            this.clearForm();
        } else {
            // Remove object references so the state does not mutate
            const selectedCollection = JSON.parse(JSON.stringify(this.state.collections[collectionIndex] || ''))
            if (selectedCollection.IsPaid && !Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) && !Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All"))) {
                alert("The watchlist you are trying to access is a paid watchlist. As a free user, you are no longer able to access this watchlist.");
                return;
            }
            const parsed = queryString.parse(this.props.location.search, { decode: false });
            let sessionOnly = false;
            if (parsed.q) {
                const base64 = parsed.q;
                const decodedSearch = window.atob(base64);
                const queryObj = JSON.parse(decodedSearch);
                sessionOnly = queryObj.sessionOnly || false;
            }
            if (returningFromSearch || !parsed.q || sessionOnly)
                this.props.history.push(`/bill-management?collection=${selectedCollection.WatchListID}`)
            this.setState({
                selectedCollectionIndex: collectionIndex,
                selectedCollection: selectedCollection,
                showCategories: false,
                billList: parsed.q ? this.state.billList : [],
                searchMade: parsed.q ? this.state.searchMade : false,
            });
            if (returningFromSearch || !parsed.q || sessionOnly) {
                const bills = selectedCollection.WatchListLegislation.filter(l => !l.DeletionDate).map(l => l.LegislationID);
                if (bills.length > 0) {
                    let legislationIds = [];
                    for (let i = 0; i < bills.length; i++) {
                        legislationIds.push({ LegislationId: bills[i] });
                    }

                    const params = {
                        LegislationIDs: legislationIds,
                        SessionID: this.state.selectedSession.SessionID
                    }
                    this.getBillsResults(params);
                }
                this.clearForm();
            }
        }
    }

    handleBillCheckbox(billIndex, e) {
        let checkedBills = [...this.state.checkedBills];
        let lastCheckedBill = this.state.lastCheckedBill;
        if (e.nativeEvent.shiftKey && this.state.lastCheckedBill !== -1) {
            checkedBills = checkedBills.fill(false);
            for (let i = 0; i < Math.abs(billIndex - this.state.lastCheckedBill); i++) {
                const index = this.state.lastCheckedBill + i * (billIndex - this.state.lastCheckedBill) / Math.abs(billIndex - this.state.lastCheckedBill);
                checkedBills[index] = true;
            }
        } else {
            lastCheckedBill = !checkedBills[billIndex] ? billIndex : this.state.lastCheckedBill;
        }
        checkedBills[billIndex] = !checkedBills[billIndex]
        this.setState({
            checkedBills: checkedBills,
            lastCheckedBill: lastCheckedBill
        });
    }

    toggleAllBills() {
        const opposite = !this.state.selectAllBills;
        let checkedBills = [...this.state.checkedBills];
        checkedBills = new Array(this.state.billList.length).fill(opposite);
        this.setState({
            selectAllBills: opposite,
            checkedBills: checkedBills,
            lastCheckedBill: -1
        });
    }

    handleEventChange(billIndex, events) {
        //The reason an array is not used here to contain all the organized events is because a bunch of components are calling this function at the same time.
        //Using the same object to contain all the organized events would cause the components to be overwriting eachother's changes.
        this.setState({
            [`organizedEvents${billIndex}`]: events
        }, () => {
            this.handleEditedBills();
        });
    }

    addEvent(eventDate, eventSequence, event) {
        this.state.billList.forEach((bill, billIndex) => {
            if (this.state.checkedBills[billIndex]) {
                bill.eventsChanged = true;
                let organizedEvents = this.state[`organizedEvents${billIndex}`] || BillHistoryForm.organizeEvents(bill.historyList || []);
                let newEvent = {
                    ...event,
                    IsPublic: true,
                    LegislationID: bill.LegislationID,
                    EventDate: eventDate.toISOString(),
                    hasChanged: true
                };
                //Check to see if there is are events with the same date already
                const newEventGroupIndex = organizedEvents ? organizedEvents.findIndex(eventGroup => eventGroup.date.isSame(eventDate, 'day')) : -1;
                if (newEventGroupIndex > -1) {
                    //Make sure DisplaySequence is handled first
                    if (organizedEvents[newEventGroupIndex].events.find(e => !e.DisplaySequence)) {
                        for (let i = 0; i < organizedEvents[newEventGroupIndex].events.length; i++) {
                            if (!organizedEvents[newEventGroupIndex].events[i].DisplaySequence) {
                                if (i === 0) {
                                    organizedEvents[newEventGroupIndex].events[i].DisplaySequence = 10;
                                } else {
                                    organizedEvents[newEventGroupIndex].events[i].DisplaySequence = organizedEvents[newEventGroupIndex].events[i - 1].DisplaySequence % 10 === 0 ? (organizedEvents[newEventGroupIndex].events[i - 1].DisplaySequence + 10) : (Math.round(organizedEvents[newEventGroupIndex].events[i - 1].DisplaySequence / 10) * 10);
                                }
                            }
                        }
                    }
                    // If the selected date already has events that occured on that date then the event is moved into the existing group
                    // If a display sequence (e.g. 30) is provided AND it is not the largest display sequence in that group, put it at the provided spot, otherwise put it last
                    if (Number.isInteger(eventSequence) && Math.max.apply(null, organizedEvents[newEventGroupIndex].events.map(o => o.DisplaySequence)) > eventSequence) {
                        newEvent.DisplaySequence = eventSequence;
                        let insertIdx;
                        organizedEvents[newEventGroupIndex].events.forEach((evt, i) => {
                            if (evt.DisplaySequence >= eventSequence) {
                                if (!Number.isInteger(insertIdx)) {
                                    newEvent.Sequence = evt.Sequence;
                                    insertIdx = i;
                                }
                                evt.Sequence++;
                                evt.hasChanged = true;
                            }
                        })
                        organizedEvents[newEventGroupIndex].events.splice(insertIdx || 0, 0, newEvent)
                    } else {
                        newEvent.Sequence = organizedEvents[newEventGroupIndex].events.length + 1;
                        newEvent.DisplaySequence = eventSequence || (organizedEvents[newEventGroupIndex].events[organizedEvents[newEventGroupIndex].events.length - 1].DisplaySequence % 10 === 0 ? (organizedEvents[newEventGroupIndex].events[organizedEvents[newEventGroupIndex].events.length - 1].DisplaySequence + 10) : (Math.round(organizedEvents[newEventGroupIndex].events[organizedEvents[newEventGroupIndex].events.length - 1].DisplaySequence / 10) * 10));
                        organizedEvents[newEventGroupIndex].events.push(newEvent)
                    }
                } else {
                    // If the selected date does not have any events then a new event group needs to be created for this date
                    newEvent.Sequence = 1;
                    newEvent.DisplaySequence = 10;
                    const newEventGroup = {
                        date: eventDate,
                        events: [newEvent]
                    };
                    // Gotta find where it goes in the list of groups
                    organizedEvents.some((eventGroup, i) => {
                        if (eventGroup.date.isAfter(eventDate, 'day')) {
                            organizedEvents.splice(i, 0, newEventGroup)
                            return true;
                        }
                        if (i === organizedEvents.length - 1) {
                            organizedEvents.push(newEventGroup)
                        }
                    });
                }
                this.setState({
                    [`organizedEvents${billIndex}`]: organizedEvents,
                    [bill.LegislationID]: true
                }, () => {
                    this.handleEditedBills();
                });
            }
        });
    }

    removeEvent(eventDate, event, displaySequence) {
        this.state.billList.forEach((bill, billIndex) => {
            if (this.state.checkedBills[billIndex]) {
                let organizedEvents = this.state[`organizedEvents${billIndex}`] || BillHistoryForm.organizeEvents(bill.historyList || []);
                organizedEvents.forEach((dateItem, dateItemIdx) => {
                    if (moment(dateItem.date).isSame(eventDate, 'day')) {
                        for (let i = 0; i < dateItem.events.length; i++) {
                            let eventItem = dateItem.events[i];
                            if (eventItem.EventCode === event.EventCode && !event.DeletionDate && (!displaySequence || displaySequence === eventItem.DisplaySequence)) {
                                //if deleting an event that didn't already exist/hasn't been saved at all, just remove it entirely
                                if (!eventItem.LegislationEventID) {
                                    //if there is only one event in this day, just remove the day
                                    if (dateItem.events.length === 1) {
                                        const wasLastEventGroup = dateItemIdx === organizedEvents.length - 1;
                                        organizedEvents.splice(dateItemIdx, 1);
                                        //if it was the last event day that got removed, expand the now-last event day
                                        if (wasLastEventGroup) {
                                            organizedEvents[organizedEvents.length - 1].hidden = false;
                                        }
                                    } else {
                                        //There is more than just this event in the day, only remove this event from the group and move on
                                        dateItem.events.splice(i, 1);

                                        //recalculate sequences of events
                                        for (let idx = i; idx < dateItem.events.length; idx++) {
                                            dateItem.events[idx].Sequence--
                                        }

                                        i--;
                                    }
                                } else {
                                    //this event existed to begin with, we need to set the deletion date and save it
                                    eventItem.DeletionDate = moment().toISOString();
                                    eventItem.hasChanged = true;
                                    this.setState({ [bill.LegislationID]: true })
                                }
                            }
                        }
                    }
                });
                this.setState({
                    [`organizedEvents${billIndex}`]: organizedEvents
                }, () => {
                    this.handleEditedBills();
                });
            }
        });
    }

    handleEditedBills() {
        let bills = [...this.state.billList];
        bills.forEach((bill, billIndex) => {
            bill.eventsChanged = false;
            if (this.state[`organizedEvents${billIndex}`]) {
                this.state[`organizedEvents${billIndex}`].forEach(eventGroup => {
                    //newly added events do not have legeventid; also encompasses initially deleted events that were un-deleted
                    let newEvents = eventGroup.events.filter(event => !event.LegislationEventID || (event.hasChanged && !event.DeletionDate && event.originallyDeleted));
                    if (newEvents.length) {
                        //mark the bill as changed, and the events as newly added (how styling is handled)
                        bill.eventsChanged = true;
                        newEvents.forEach(evt => evt.isNewlyAdded = true);
                    }

                    //newly deleted events have a legeventid, deletion date, and hasChanged property without originallyDeleted
                    let deletedEvents = eventGroup.events.filter(event => event.LegislationEventID && event.hasChanged && event.DeletionDate && !event.originallyDeleted);
                    if (deletedEvents.length) {
                        //mark the bill as changed, and the events as newly deleted (how styling is handled)
                        bill.eventsChanged = true;
                        deletedEvents.forEach(evt => evt.isNewlyDeleted = true);
                    }

                    //handle events that were initially deleted, then un-deleted, then re-deleted
                    let reDeletedEvents = eventGroup.events.filter(event => event.LegislationEventID && event.hasChanged && event.DeletionDate && event.originallyDeleted);
                    if (reDeletedEvents.length) {
                        //do not mark the bill as changed - these were reverted; but do reset the events themselves
                        reDeletedEvents.forEach(evt => evt.isNewlyAdded = false);
                    }

                    //for any events that are not deleted, mark as non deleted (important for resetting events that were deleted and then un-deleted)
                    let nonDeletedEvents = eventGroup.events.filter(event => event.LegislationEventID && !event.DeletionDate);
                    nonDeletedEvents.forEach(evt => evt.isNewlyDeleted = false);

                    //date changed events
                    let dateChangedEvents = eventGroup.events.filter(event => event.LegislationEventID && event.origDate && !moment(event.origDate).isSame(moment(event.EventDate)));
                    if (dateChangedEvents.length) {
                        //mark the bill as changed, and the events as 'dateChanged'
                        bill.eventsChanged = true;
                        dateChangedEvents.forEach(evt => evt.dateChanged = true);
                    }

                    //for any events that are non-date-changed, mark as such (important for resetting events that were changed and then reverted)
                    let nonDateChangedEvents = eventGroup.events.filter(event => event.LegislationEventID && (!event.origDate || moment(event.origDate).isSame(event.EventDate)));
                    nonDateChangedEvents.forEach(evt => evt.dateChanged = false);

                    //sequence changed events
                    let sequenceChangedEvents = eventGroup.events.filter(event => event.LegislationEventID && event.origSequence && event.Sequence !== event.origSequence)
                    if (sequenceChangedEvents.length) {
                        //mark the bill as changed, and the events as 'sequenceChanged'
                        bill.eventsChanged = true;
                        sequenceChangedEvents.forEach(evt => evt.sequenceChanged = true);
                    }

                    //for any events that are non-sequence-changed, mark as such (important for resetting events that were changed and then reverted)
                    let nonSequenceChangedEvents = eventGroup.events.filter(event => event.LegislationEventID && (!event.origSequence || event.Sequence === event.origSequence));
                    nonSequenceChangedEvents.forEach(evt => evt.sequenceChanged = false);

                    //description changed events
                    let descriptionChangedEvents = eventGroup.events.filter(event => event.LegislationEventID && event.origDescription && event.Description !== event.origDescription)
                    if (descriptionChangedEvents.length) {
                        //mark the bill as changed, and the events as 'descriptionChanged'
                        bill.eventsChanged = true;
                        descriptionChangedEvents.forEach(evt => evt.descriptionChanged = true);
                    }

                    //for any events that are non-description-changed, mark as such (important for resetting events that were changed and then reverted)
                    let nonDescriptionChangedEvents = eventGroup.events.filter(event => event.LegislationEventID && (!event.origDescription || event.Description === event.origDescription));
                    nonDescriptionChangedEvents.forEach(evt => evt.descriptionChanged = false);

                    //event code changed events
                    let eventCodeChangedEvents = eventGroup.events.filter(event => event.LegislationEventID && event.origEventCode && event.EventCode !== event.origEventCode)
                    if (eventCodeChangedEvents.length) {
                        //mark the bill as changed, and the events as 'eventCodeChanged'
                        bill.eventsChanged = true;
                        eventCodeChangedEvents.forEach(evt => evt.eventCodeChanged = true);
                    }

                    //for any events that are non-event-code-changed, mark as such (important for resetting events that were changed and then reverted)
                    let nonEventCodeChangedEvents = eventGroup.events.filter(event => event.LegislationEventID && (!event.origEventCode || event.EventCode === event.origEventCode));
                    nonEventCodeChangedEvents.forEach(evt => evt.eventCodeChanged = false);
                })
            }
        });
        this.setState({ billList: bills });
    }

    saveForm() {
        let eventsToSave = [];
        this.state.billList.forEach((_bill, billIndex) => {
            if (this.state[`organizedEvents${billIndex}`]) {
                this.state[`organizedEvents${billIndex}`].forEach(eventGroup => {
                    eventGroup.events.forEach(event => {
                        //Only save events that have been changed
                        if (event.hasChanged) {
                            //Do not save the events that have been deleted and lack a event id. These events to do not need to be created
                            if (!event.LegislationEventID && event.DeletionDate) {
                                return
                            } else {
                                eventsToSave.push(event);
                            }
                        }
                    })
                });
            }
        });
        if (eventsToSave.length > 0) {
            this.setState({
                isSaving: true
            });
            this.props.actions.saveBillEvent({ LegislationEvents: eventsToSave })
                .then(() => {
                    this.props.actions.makeToast([{ message: "Events Saved", type: "success" }]);
                    this.setState({
                        historyIsLoaded: false,
                        isSaving: false
                    });
                    // If there is a selected collection then do not do a search, just get those bills again
                    if (this.state.selectedCollection) {
                        this.selectCollection(this.state.selectedCollectionIndex)
                    } else {
                        this.handleSearch();
                    }
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Events Save Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false
                    });
                });
        }
    }

    toggleSidebar() {
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }

    toggleShowData(billID, bool) {
        if (bool === undefined) {
            this.setState(prevState => ({
                [billID]: !prevState[billID]
            }));
        } else {
            //not just toggling, we're explicitly setting (e.g. if searching by keyword, and a bill has been expanded, if the keyword search location changes to bill text,
            //don't just toggle all (which would reset that bill to false), set them all to true)
            this.setState({
                [billID]: bool
            })
        }
    }

    togglehideAllEvents(idx) {
        let hideAllEvents = [...this.state.hideAllEvents]
        const hide = !hideAllEvents[idx];
        hideAllEvents[idx] = hide
        this.setState({
            hideAllEvents
        }, () => {
            if (!hide) {
                const lastEventGroup = document.getElementById("last-" + idx);
                if (lastEventGroup) { lastEventGroup.scrollIntoView({ block: 'center' }); }
            }
        })
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    handleExcludeFailedChange() {
        this.setState({ excludeFailed: !this.state.excludeFailed })
    }

    toggleExpandAllLegislation(expand) {
        let billList = [...this.state.billList];
        billList.forEach(bill => {
            this.toggleShowData(bill.LegislationID, expand)
        })
    }

    render() {
        const { isFetching, sessionID, billList, showSidebar, height } = this.state;
        const { errorMessage } = this.props.bills;

        let toggleArrows = [];
        for (let i = 1; i <= height; i++) {
            let top = (i * 540).toString() + "px";
            toggleArrows.push(<button key={i} type="button" aria-expanded={showSidebar} onClick={this.toggleSidebar} className={showSidebar ? "sidebar-button arrow-up white-chevron" : "sidebar-button arrow-down white-chevron"} style={{ top: top }}></button>);
        }

        return (
            <div id="results-div">
                {this.state.showModal &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content">
                            <p>You will need to click the search button or press the enter key in order to update your search results after removing a search parameter.</p>
                            <button className="button primary float-right" onClick={() => this.toggleModal()}>Close</button>
                            <br />
                        </div>
                    </div>
                }
                <h1>Bill Management</h1>
                <br />
                <div className={showSidebar ? "inner-grid one-toggle-and-three" : "inner-grid toggle-and-three"}>
                    {showSidebar &&
                        <div>
                            <LegislationWatchlists
                                collections={this.state.collections}
                                isLoading={this.state.collectionIsLoading}
                                error={this.props.collection.collectionError}
                                selectedCollectionIndex={this.state.selectedCollectionIndex}
                                selectCollection={this.selectCollection}
                                createCollection={() => null}
                                isCreatingCollection={this.state.isCreatingCollection}
                                location={this.props.history.location.pathname}
                                isBillManagement={true}
                                isAuthor={true}
                            />
                            <br />
                            <AdvancedSearchBox
                                ref={this.searchBox}
                                selectedBillNumbers={this.state.selectedBillNumbers}
                                selectedBillRangeBeginning={this.state.selectedBillRangeBeginning}
                                selectedBillRangeEnd={this.state.selectedBillRangeEnd}
                                selectedChapterNumber={this.state.selectedChapterNumber}
                                selectedKeywords={this.state.selectedKeywords}
                                selectedBillEvent={this.state.selectedBillEvent}
                                billEventOptions={this.state.billEventOptions}
                                sessionOptions={this.state.sessionOptions}
                                selectedSession={this.state.selectedSession}
                                billStatusCategoryList={this.state.billStatusCategoryList}
                                selectedBillStatusCategory={this.state.selectedBillStatusCategory}
                                billStatusStartDate={this.state.billStatusStartDate}
                                billStatusEndDate={this.state.billStatusEndDate}
                                selectedCommittee={this.state.selectedCommittee}
                                committeeList={this.state.filteredCommitteeList}
                                subcommitteeList={this.state.subcommitteeList}
                                selectedSubcommittee={this.state.selectedSubcommittee}
                                patronList={this.state.filteredPatronList}
                                patronRoles={this.state.patronRoles}
                                selectedPatron={this.state.selectedPatron}
                                selectedPatronType={this.state.selectedPatronType}
                                selectedChamber={this.state.selectedChamber}
                                selectedBillType={this.state.selectedBillType}
                                billEventEndDate={this.state.billEventEndDate}
                                billEventStartDate={this.state.billEventStartDate}
                                resetUrl={this.resetUrl}
                                handleSubmit={this.handleSubmit}
                                handleTextChange={this.handleTextChange}
                                handleSelectChange={this.handleSelectChange}
                                handleDateChange={this.handleDateChange}
                                committeeChange={this.committeeChange}
                                chamberChange={this.chamberChange}
                                sessionChange={this.sessionChange}
                                clearForm={this.clearForm}
                                isFetching={this.state.isFetching}
                                handlePatronChange={this.handlePatronChange}
                                handlePatronTypeChange={this.handlePatronTypeChange}
                                subjectList={this.state.subjectList}
                                selectedSubject={this.state.selectedSubject}
                                toggleGroup={this.toggleGroup}
                                billFieldset={this.state.billFieldset}
                                keywordFieldset={this.state.keywordFieldset}
                                statusFieldset={this.state.statusFieldset}
                                activityFieldset={this.state.activityFieldset}
                                membersFieldset={this.state.membersFieldset}
                                committeesFieldset={this.state.committeesFieldset}
                                subjectFieldset={this.state.subjectFieldset}
                                showCategories={this.state.showCategories}
                                toggleShowCategories={this.toggleShowCategories}
                                location={this.props.history.location.pathname}
                                billVersionList={this.state.billVersionList}
                                selectedBillVersion={this.state.selectedBillVersion}
                                billSummaryList={this.state.billSummaryList}
                                selectedSummaryVersion={this.state.selectedSummaryVersion}
                                selectedLocation={this.state.selectedLocation}
                                billManagement={true}
                                handleCurrentStatusChange={this.handleCurrentStatusChange}
                                currentStatus={this.state.currentStatus}
                                failedToGetSessions={this.state.failedToGetSessions}
                                searchParam={this.props.history.location.search}
                                handleExcludeFailedChange={this.handleExcludeFailedChange}
                                excludeFailed={this.state.excludeFailed}
                            />
                        </div>
                    }
                    <div className={showSidebar ? "management sidebar-toggle-open" : "management sidebar-toggle-closed"} onClick={this.toggleSidebar}>
                        {toggleArrows.map((a, i) => {
                            return (a)
                        })}
                    </div>
                    {this.state.failedToGetSessions ? <div className="center input-feedback" style={{ fontSize: '1em' }}>Failed to retrieve sessions. Please refresh & try again.</div> : !this.state.selectedSession ? <div className="center-spinner spinner">Loading...</div>
                        :
                        this.state.showCategories ?
                            <BillCategories
                                session={this.state.sessionOptions.find(session => session.SessionCode === this.props.nav.session)}
                                patronList={this.state.patronList}
                                committeeList={this.state.groupedCommitteeList}
                                subjectList={this.state.subjectList}
                                introDateList={this.state.introDateList}
                                statusCategoriesList={this.state.billStatusCategoryList}
                                isLoading={this.state.billStatusCategoryListIsLoading}
                                history={this.props.history}
                                location={this.props.history.location.pathname}
                                selectedSession={this.state.selectedSession}
                                excludeFailed={this.state.excludeFailed}
                            />
                            :
                            <div className="public-list">
                                <CollectionControls
                                    searchMade={this.state.searchMade}
                                    collections={this.state.collections}
                                    selectedCollection={this.state.selectedCollection}
                                    selectedCollectionIndex={this.state.selectedCollectionIndex}
                                    handleCollectionChange={() => null}
                                    selectCollection={this.selectCollection}
                                    collectionIsSaving={this.state.collectionIsSaving}
                                    saveCollection={() => null}
                                    deleteCollection={() => null}
                                    calendarHeader={this.state.calendarHeader}
                                    isBillManagement={true}
                                />
                                {this.state.searchMade &&
                                    <SearchSelections
                                        currentStatus={this.state.currentStatus}
                                        selectedSession={this.state.selectedSession}
                                        selectedBillNumbers={this.state.selectedBillNumbers}
                                        selectedBillRangeBeginning={this.state.selectedBillRangeBeginning}
                                        selectedBillRangeEnd={this.state.selectedBillRangeEnd}
                                        selectedChapterNumber={this.state.selectedChapterNumber}
                                        selectedKeywords={this.state.selectedKeywords}
                                        selectedBillEvent={this.state.billEventOptions.find(opt => opt.value === this.state.selectedBillEvent)}
                                        selectedBillStatusCategory={this.state.billStatusCategoryList.find(opt => opt.value === this.state.selectedBillStatusCategory)}
                                        billStatusStartDate={this.state.billStatusStartDate}
                                        billStatusEndDate={this.state.billStatusEndDate}
                                        selectedCommittee={this.state.committeeList.find(opt => opt.value === this.state.selectedCommittee)}
                                        selectedSubcommittee={this.state.subcommitteeList.find(opt => opt.value === this.state.selectedSubcommittee)}
                                        selectedPatron={this.state.patronList.find(opt => opt.value === this.state.selectedPatron)}
                                        selectedPatronType={this.state.patronRoles.filter(role => this.state.selectedPatronType.includes(role.PatronTypeID))}
                                        selectedChamber={this.state.selectedChamber}
                                        billEventEndDate={this.state.billEventEndDate}
                                        billEventStartDate={this.state.billEventStartDate}
                                        selectedSubject={this.state.subjectList.find(opt => opt.value === this.state.selectedSubject)}
                                        excludeFailed={this.state.excludeFailed}
                                        handleSelectionClear={this.handleSelectionClear}
                                        handleMultiSelectionClear={this.handleMultiSelectionClear}
                                        updateSearch={this.state.showUpdateSearchButton ? this.searchBox.current.handleSubmit : null}
                                    />}
                                <EventControls
                                    billEventOptions={this.state.billEventOptions}
                                    addEvent={this.addEvent}
                                    removeEvent={this.removeEvent}
                                    checkedBills={this.state.checkedBills}
                                    patronList={this.state.patronList}
                                    committeeList={this.state.fullCommitteeList}
                                    actionTypes={this.state.actionTypes}
                                    textList={this.state.billList.flatMap(bill => bill.versionsList ? [...bill.versionsList] : [])}
                                    otherBillEventOptions={this.state.otherBillEventOptions}
                                    getOtherBillEventOptions={this.getOtherBillEventOptions}
                                />
                                {!isFetching &&
                                    <div className="flex-row search-results-title-section">
                                        {this.state.searchMade ?
                                            <>
                                                {this.state.allLegislation && <h2>All legislation</h2>}
                                                {this.state.mostFrequent && <h2>Most frequently accessed bills</h2>}
                                                {this.state.isPending && <h2>Pending bills</h2>}
                                                {!this.state.isPending && !this.state.mostFrequent && !this.state.allLegislation && <h2 style={{ marginBottom: '0px' }}>{billList.length === 0 && "No "}Search Results</h2>}
                                            </>
                                            : <div></div>}
                                    </div>
                                }
                                <div className="search-results">
                                    {isFetching ?
                                        <div className="spinner"></div>
                                        :
                                        <>
                                            {errorMessage ?
                                                <p className="message-error">{errorMessage}</p>
                                                :
                                                null
                                            }
                                        </>
                                    }
                                    {!isFetching && !errorMessage &&
                                        <ul className="slidedown-list">
                                            <div className="flex-row">
                                                <div />
                                                <div>
                                                    {billList.length ? <button className="button-link" style={{ fontSize: '12px' }} onClick={() => this.toggleExpandAllLegislation(billList.find(bill => !this.state[bill.LegislationID]))}>{billList.find(bill => !this.state[bill.LegislationID]) ? "Expand All Legislation" : "Collapse All Legislation"}</button> : null}
                                                </div>
                                            </div>
                                            <div className='flex-row'>
                                                {billList.length ? <label htmlFor="sr-select-all"
                                                    className="txt-greyed">
                                                    <input id="sr-select-all" checked={this.state.selectAllBills} onChange={this.toggleAllBills} type="checkbox" style={{ verticalAlign: 'sub', marginRight: '10px' }} />
                                                    Select all</label> : <label />}
                                                {!errorMessage && <span id="search-result-count" className="txt-greyed">{billList.length} {this.state.searchMade ? "Result" : "Item"}{billList.length !== 1 && "s"}</span>}
                                            </div>
                                            <hr className="faded-line" style={{ marginBlockEnd: "0px" }} />
                                            {billList.map((bill, i) =>
                                                <BillInfoComponent
                                                    key={i}
                                                    bill={bill}
                                                    billIndex={i}
                                                    checked={this.state.checkedBills[i]}
                                                    expanded={this.state[bill.LegislationID]}
                                                    toggleShowData={this.toggleShowData}
                                                    selectedCollection={this.state.selectedCollection}
                                                    handleCollectionChange={() => null}
                                                    billEvents={this.state.billEventOptions}
                                                    handleBillCheckbox={e => this.handleBillCheckbox(i, e)}
                                                    sessionCode={this.state.sessionOptions.find(session => session.SessionID === bill.SessionID).SessionCode}
                                                    isBillManagement={true}
                                                    selectedLocation={this.state.displaySelectedLocation}
                                                    selectedKeywords={this.state.selectedKeywords}
                                                    displayChapterNumber={this.state.selectedChapterNumber || (this.state.selectedBillStatusCategory && this.state.billStatusCategoryList.find(it => it.Name.toLowerCase() === "acts of assembly chapters") && this.state.selectedBillStatusCategory === this.state.billStatusCategoryList.find(it => it.Name.toLowerCase() === "acts of assembly chapters").LegislationCategoryID)}
                                                >
                                                    <div style={{ fontSize: '16px' }}>
                                                        <BillHistoryForm
                                                            historyList={bill.historyList || []}
                                                            organizedEvents={this.state[`organizedEvents${i}`] || []}
                                                            handleEventChange={events => this.handleEventChange(i, events)}
                                                            isBillManagement={true}
                                                            billEvents={this.state.billEventOptions}
                                                            billData={bill}
                                                            idx={i}
                                                            handleEditedBills={this.handleEditedBills}
                                                            toggleEditContent={this.toggleEditContent}
                                                            saveEvents={this.saveEvents}
                                                            hideAllEvents={this.state.hideAllEvents[i]}
                                                            togglehideAllEvents={this.togglehideAllEvents}
                                                            versionsList={bill.versionsList}
                                                            actionTypes={this.state.actionTypes}
                                                            memberList={this.state.patronList}
                                                            committeeList={this.state.fullCommitteeList}
                                                        />
                                                    </div>
                                                </BillInfoComponent>
                                            )}
                                        </ul>
                                    }
                                </div>
                            </div>
                    }
                </div>
                {
                    !this.state.showCategories &&
                    <div className="floating-button-bar align-right invisible-bar">
                        <button type="button" onClick={this.saveForm} disabled={!this.state.billList.find(b => b.eventsChanged) || this.state.isSaving} className="button">{this.state.isSaving ? "Saving..." : "Save"}</button>
                    </div>
                }
            </div>
        )
    }
}

const PublicBillSearch = connect(
    (state) => {
        const { bills, session, members, committee, patrons, nav, collection, login, minutes } = state;
        return {
            bills,
            session,
            members,
            committee,
            patrons,
            nav,
            collection,
            login,
            minutes
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, committeeActionCreators, billActionCreators, sessionActionCreators, memberActionCreators, patronActionCreators, navActionCreators, collectionActionCreators, minutesActionCreators), dispatch)
        }
    }
)(BillManagement)

export default PublicBillSearch;

