import React from 'react';
import moment from 'moment-timezone';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import * as Fields from '../../lis-shared/lis-layout/components/lis-forms-component';

// The parent component is in charge of gathering all the data for the form's dropdowns
// The paren't state object is updated with the ID of whatever the user selects
// Normally, the state object is updated with the entire object that the user selects.
// But, to be able to make a query from another page either the whole object needs to be passed in the url
// Or just the ID. Since it is easier to pass just the ID in the url to the advanced search page
// the dropdowns find the object that has the ID that was passed.

// As an example, a session ID of 47 is in the url.
// So the dropdown selects its value by searching through the available sessions and finding the one that has an ID of 47

const singleStyles = {
    menuList: (base, state) => ({
        ...base,
        paddingTop: '0px',
    }),
    group: (base, state) => ({
        ...base,
        paddingTop: '0px',
    }),
    groupHeading: (base, state) => ({
        ...base,
        paddingTop: '7px',
        paddingBottom: '7px',
        marginBottom: '0px',
        position: 'sticky',
        top: '0px',
        backgroundColor: 'hsl(0,0%,100%)'
    }),
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: '0px 8px'
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: '0px 8px'
    }),
    singleValue: (base, state) => {
        return { ...base, };
    }
}

class AdvancedSearchBoxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billStatusDateError: false,
            showFindSection: false,
            showCategorySection: false,
            showTooltip: false
        };

        this.toggleFindSection = this.toggleFindSection.bind(this);
        this.toggleCategorySection = this.toggleCategorySection.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.setOpenSection = this.setOpenSection.bind(this);
    }

    toggleFindSection() {
        this.setState(state => ({
            showFindSection: !state.showFindSection,
            showCategorySection: !state.showCategorySection
        }), () => {
            this.props.toggleShowCategories(!this.props.showCategories)
        });
    }

    toggleCategorySection() {
        this.setState(state => ({
            showCategorySection: !state.showCategorySection,
            showFindSection: !state.showFindSection
        }), () => {
            this.props.toggleShowCategories(!this.props.showCategories)
        });
    }

    toggleTooltip(state) {
        let showTooltip = { ...this.state.showTooltip };

        //Close all open tooltips
        if (typeof showTooltip === "object") {
            for (const x in showTooltip) {
                if (x !== state)
                    showTooltip[x] = false;
            };
        }

        showTooltip[state] = showTooltip[state] ? false : true;

        this.setState({
            showTooltip: showTooltip
        })
    }

    handleSubmit() {
        if (this.props.failedToGetSessions) { return; }
        // If the status start date and end date are just the session start and end date then just send the session ID. The database query is a little quicker with just the session ID
        let { billStatusStartDate, billStatusEndDate } = this.props;
        if (!this.props.currentStatus && this.props.selectedBillStatusCategory) {
            if (this.props.selectedSession) {
                const adjournmentEvent = this.props.selectedSession.SessionEvents.find(event => event.DisplayName === 'Adjournment');
                if (this.props.selectedSession.SessionEvents.find(event => event.DisplayName === "Session Start") && moment(this.props.selectedSession.SessionEvents.find(event => event.DisplayName === "Session Start").ActualDate).isSame(billStatusStartDate) &&
                    (adjournmentEvent ? moment(adjournmentEvent.ActualDate) : moment().endOf('day')).isSame(billStatusEndDate)) {
                    billStatusStartDate = null;
                    billStatusEndDate = null;
                }
            }
        }
        //Create an object to be encoded to base64 and stuck in the url
        let queryObj = {
            selectedBillNumbers: this.props.selectedBillNumbers,
            selectedBillRangeBeginning: this.props.selectedBillRangeBeginning,
            selectedBillRangeEnd: this.props.selectedBillRangeEnd,
            selectedChapterNumber: this.props.selectedChapterNumber,
            selectedKeywords: this.props.selectedKeywords,
            selectedLocation: this.props.selectedLocation,
            selectedSummaryVersion: this.props.selectedSummaryVersion,
            selectedBillVersion: this.props.selectedBillVersion,
            selectedSession: this.props.selectedSession.SessionID,
            selectedBillStatusCategory: this.props.selectedBillStatusCategory,
            billStatusStartDate: billStatusStartDate,
            billStatusEndDate: billStatusEndDate,
            selectedCommittee: this.props.selectedCommittee,
            selectedSubcommittee: this.props.selectedSubcommittee,
            selectedPatron: this.props.selectedPatron,
            selectedSubject: this.props.selectedSubject,
            selectedPatronType: this.props.selectedPatronType,
            selectedChamber: this.props.selectedChamber,
            selectedBillType: this.props.selectedBillType,
            selectedBillEvent: this.props.selectedBillEvent,
            billEventStartDate: this.props.billEventStartDate,
            billEventEndDate: this.props.billEventEndDate,
            billActionStartDate: this.props.billActionStartDate,
            billActionEndDate: this.props.billActionEndDate,
            keywordUseGlobalSessionSearch: this.props.keywordUseGlobalSessionSearch,
            currentStatus: this.props.currentStatus,
            excludeFailed: this.props.excludeFailed
        }
        this.props.handleSubmit(queryObj)
    }

    setOpenSection() {
        //900px is when mobile layout is used (set in mobile.scss); collapse by default : false
        this.setState({
            showCategorySection: this.props.showCategories || this.props.searchParam.length === 0 ? window.innerWidth <= 900 ? false : true : false,
            showFindSection: this.props.showCategories || this.props.searchParam.length === 0 ? false : window.innerWidth <= 900 ? false : true
        })
    }

    componentDidMount() {
        this.setOpenSection();
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchParam !== prevProps.searchParam) {
            if (this.props.searchParam === "") {
                this.setState({
                    showCategorySection: true,
                    showFindSection: false
                })
            } else {
                this.setState({
                    showCategorySection: false,
                    showFindSection: true
                })
            }
        }
    }

    render() {
        const getSelectedCommitteeLabel = (val) => {
            if (!val) return null;
            let newCmte = { ...val } //don't modify existing committee values stored at state level & used in dropdown
            if (newCmte && newCmte.label) {
                newCmte.label = '(' + newCmte.ChamberCode + ') ' + newCmte.label;
            }
            return newCmte;
        }
        return (
            <React.Fragment>
                <div className={this.props.location.includes('bill-management') ? "dlas-forms side-form advanced-search management" : "dlas-forms side-form advanced-search"}>
                    <div className="header flex-row flex-vertical-center" style={!this.state.showCategorySection ? { marginBottom: "20px" } : {}}>
                        <h3 className="center category-header">View Bills By Category</h3>
                        <button type="button" aria-expanded={this.state.showCategorySection} onClick={this.toggleCategorySection} className={this.state.showCategorySection ? "arrow-up white-chevron" : "arrow-down white-chevron"}></button>
                    </div>
                    {this.state.showCategorySection &&
                        <div style={{ marginBottom: "20px" }}>
                            <div>
                                <div onClick={() => { this.props.showCategories ? null : this.props.toggleShowCategories(!this.props.showCategories) }} className="side-form-group advanced-search-fieldset-header" style={this.props.showCategories ? { cursor: 'pointer', backgroundColor: '#e0e0e0' } : { cursor: 'pointer' }}>
                                    <span className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Bills by Category</span>
                                    <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip("billCategories") }} className="button info">Info</a>
                                    {this.state.showTooltip["billCategories"] &&
                                        <div className="info-box search-tooltip">
                                            <p>This is a view where we provide easy to access, common categories of legislation.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="side-form-group advanced-search-fieldset-header">
                                    <label htmlFor="exclude-failed-button" className={(this.props.location.includes('bill-search') ? "txt-dark-blue " : "") + `no-background${this.props.keywordUseGlobalSessionSearch ? ' txt-greyed' : ''}`} style={{ whiteSpace: "no-wrap", marginTop: '0px', fontSize: "17px" }}>Exclude Failed Legislation</label>
                                    <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip("excludeFailed") }} className="button info">Info</a>
                                    {this.state.showTooltip["excludeFailed"] &&
                                        <div className="info-box search-tooltip">
                                            <p>{"Turning this switch on will exclude all failed bills from your search results."}</p>
                                            <p>{"This option will not be honored for the following category searches:"}
                                                <ul>
                                                    <li>In Conference</li>
                                                    <li>Failed in Conference</li>
                                                    <li>Approved or enacted</li>
                                                    <li>Vetoed</li>
                                                    <li>Acts of Assembly chapters</li>
                                                    <li>Failed</li>
                                                    <li>Pending</li>
                                                </ul>
                                            </p>
                                            <p>{"This option will be disabled if you are performing a keyword search across all sessions."}</p>
                                        </div>
                                    }
                                    <div className="flex-row flex-vertical-center flex-start" style={{ marginBottom: '3%' }}>
                                        <div className="toggle-switch" disabled={this.props.keywordUseGlobalSessionSearch} onClick={() => this.props.handleExcludeFailedChange()}>
                                            <input id="exclude-failed-button" checked={this.props.excludeFailed} type="checkbox" style={{ width: "auto" }} />
                                            <span className="slider"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className={this.props.location.includes('bill-management') ? "dlas-forms side-form advanced-search management" : "dlas-forms side-form advanced-search"}>
                    <div className="header flex-row flex-vertical-center">
                        <h3 className="center category-header">Find a Bill</h3>
                        <button type="button" aria-expanded={this.state.showFindSection} onClick={this.toggleFindSection} className={this.state.showFindSection ? "arrow-up white-chevron" : "arrow-down white-chevron"}></button>
                    </div>
                    {this.state.showFindSection &&
                        <div className="content">
                            <div>
                                <div className="side-form-group advanced-search-fieldset-header">
                                    <label htmlFor="exclude-failed-button" className={(this.props.location.includes('bill-search') ? "txt-dark-blue " : "") + `no-background${this.props.keywordUseGlobalSessionSearch ? ' txt-greyed' : ''}`} style={{ whiteSpace: "no-wrap", marginTop: '0px', fontSize: "17px" }}>Exclude Failed Legislation</label>
                                    <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip("excludeFailed") }} className="button info">Info</a>
                                    {this.state.showTooltip["excludeFailed"] &&
                                        <div className="info-box search-tooltip">
                                            <p>{"Turning this switch on will exclude all failed bills from your search results."}</p>
                                            <p>{"This option will be disabled if you are performing a keyword search across all sessions."}</p>
                                        </div>
                                    }
                                    <div className="flex-row flex-vertical-center flex-start" style={{ marginBottom: '3%' }}>
                                        <div className="toggle-switch" disabled={this.props.keywordUseGlobalSessionSearch} onClick={() => this.props.handleExcludeFailedChange()}>
                                            <input id="exclude-failed-button" checked={this.props.excludeFailed} type="checkbox" style={{ width: "auto" }} />
                                            <span className="slider"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={() => this.props.toggleGroup("billFieldset")} className="advanced-search-fieldset-header">
                                <span className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Bill Number</span>
                                <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip("billNumber") }} className="button info">Info</a>
                                {this.state.showTooltip["billNumber"] &&
                                    <div className="info-box search-tooltip">
                                        <p>{"Use > to search for all legislation with a number after the one entered (HB5> returns all house bills with a number after 5)"}</p>
                                        <p>{"Use < to search for all legislation with a number before the one entered (HB5< returns all house bills with a number before 5)"}</p>
                                        <p>{"Use a comma to search for a list of two or more bills (HB53, HB98)"}</p>
                                    </div>
                                }
                                <button aria-expanded={this.props.billFieldset} type="button" className={this.props.billFieldset ? "arrow-up" : "arrow-down"}></button>
                            </div>
                            {this.props.billFieldset &&
                                <fieldset>
                                    <div>
                                        <Fields.TextInput
                                            type="text"
                                            id="se-bill-numbers"
                                            label="Bill Number(s)"
                                            onChange={(evt) => this.props.handleTextChange(evt, 'selectedBillNumbers')}
                                            value={this.props.selectedBillNumbers}
                                            placeholder="ex. hb1"
                                        />
                                    </div>
                                    <div className="multi-row two two-mobile no-margin">
                                        <div>
                                            <Fields.TextInput
                                                type="text"
                                                id="se-bill-range-start"
                                                label="Bill Range"
                                                onChange={(evt) => this.props.handleTextChange(evt, 'selectedBillRangeBeginning')}
                                                value={this.props.selectedBillRangeBeginning}
                                                placeholder="ex. hb1"
                                            />
                                        </div>
                                        <div>
                                            <br />
                                            <label htmlFor="se-bill-range-end" className="screen-reader-only">Bill range end</label>
                                            <input
                                                type="text"
                                                id="se-bill-range-end"
                                                onChange={(evt) => this.props.handleTextChange(evt, 'selectedBillRangeEnd')}
                                                value={this.props.selectedBillRangeEnd}
                                                placeholder="ex. hb10"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Fields.TextInput
                                            type="text"
                                            id="se-chapter-numbers"
                                            label="Chapter Number"
                                            onChange={(evt) => this.props.handleTextChange(evt, 'selectedChapterNumber')}
                                            value={this.props.selectedChapterNumber}
                                            placeholder={"ex. ch3"}
                                        />
                                    </div>
                                </fieldset>
                            }
                            <div style={{ cursor: 'pointer' }} onClick={() => this.props.toggleGroup("keywordFieldset")} className="advanced-search-fieldset-header">
                                <span className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Keyword</span>
                                <a onClick={(e) => { e.stopPropagation(e); this.props.toggleHelpModal() }} title="Search Help" className="button info">Info</a>
                                <button aria-expanded={this.props.keywordFieldset} type="button" className={this.props.keywordFieldset ? "arrow-up" : "arrow-down"}></button>
                            </div>
                            {this.props.keywordFieldset &&
                                <fieldset>
                                    {!this.props.billManagement &&
                                        <React.Fragment>
                                            <div className="multi-row two-max" >
                                                <div className="label-and-radio">
                                                    <label htmlFor="selected-session-button" className={`label radio-label`}>{this.props.selectedSession ? this.props.selectedSession.SessionYear + " " + this.props.selectedSession.DisplayName : 'Selected Session'}</label>
                                                    <input id="selected-session-button" checked={this.props.keywordUseGlobalSessionSearch === false} onChange={() => this.props.handleRadioChange()} type="radio" />
                                                </div>
                                                <div className="label-and-radio">
                                                    <label htmlFor="all-sessions-button" className={`label radio-label`}>All Sessions</label>
                                                    <input id="all-sessions-button" checked={this.props.keywordUseGlobalSessionSearch === true} onChange={() => this.props.handleRadioChange()} type="radio" />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div>
                                        <label htmlFor="se-keywords" className="screen-reader-only">Keywords</label>
                                        <Fields.TextInput
                                            type="text"
                                            id="se-keywords"
                                            onChange={(evt) => this.props.handleTextChange(evt, 'selectedKeywords')}
                                            value={this.props.selectedKeywords}
                                            placeholder={this.props.selectedLocation === "Caption" ? "A brief description of the bill" : "Keyword"}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <label>Search within</label>
                                        </div>
                                        <div className="checkbox-container">
                                            <div className="checkbox flex-row flex-vertical-center flex-start">
                                                <div className="toggle-switch" onClick={() => this.props.handleSelectChange({ value: this.props.selectedLocation !== 'Caption' ? 'Caption' : '' }, 'selectedLocation')}>
                                                    <input id="se-caption" checked={this.props.selectedLocation === 'Caption'} type="checkbox" style={{ width: "auto" }} />
                                                    <span className="slider"></span>
                                                </div>
                                                <label htmlFor="se-caption" className="checkbox-label no-background" style={{ whiteSpace: "no-wrap", marginTop: '0px' }}>Caption</label>
                                            </div>
                                            <hr className="faded-line" />
                                            <div className="checkbox">
                                                <div className="flex-row flex-vertical-center flex-start">
                                                    <div className="toggle-switch" onClick={() => this.props.handleSelectChange({ value: this.props.selectedLocation !== 'Bill Text' ? 'Bill Text' : '' }, 'selectedLocation')}>
                                                        <input id="se-bill-text" checked={this.props.selectedLocation === 'Bill Text'} type="checkbox" style={{ width: "auto" }} />
                                                        <span className="slider"></span>
                                                    </div>
                                                    <label htmlFor="se-bill-text" className="checkbox-label no-background" style={{ whiteSpace: "no-wrap", marginTop: '0px' }}>Bill Text</label>
                                                </div>
                                                <label htmlFor="sr-bill-text-type" className="screen-reader-only" >Bill text type</label>
                                                <Select
                                                    id="sr-bill-text-type"
                                                    isDisabled={this.props.selectedLocation !== 'Bill Text'}
                                                    styles={singleStyles}
                                                    options={this.props.billVersionList}
                                                    value={this.props.billVersionList.find(ver => ver.value === this.props.selectedBillVersion)}
                                                    onChange={opt => this.props.handleSelectChange(opt, 'selectedBillVersion')} />
                                            </div>
                                            <hr className="faded-line" />
                                            <div className="checkbox">
                                                <div className="flex-row flex-vertical-center flex-start">
                                                    <div className="toggle-switch" onClick={() => this.props.handleSelectChange({ value: this.props.selectedLocation !== 'Summary' ? 'Summary' : '' }, 'selectedLocation')}>
                                                        <input id="se-bill-summary" checked={this.props.selectedLocation === 'Summary'} type="checkbox" style={{ width: "auto" }} />
                                                        <span className="slider"></span>
                                                    </div>
                                                    <label htmlFor="se-bill-summary" className="checkbox-label no-background" style={{ whiteSpace: "no-wrap", marginTop: '0px' }}>Bill Summary</label>
                                                </div>
                                                <label htmlFor="sr-bill-summary-type" className="screen-reader-only" >Bill summary type</label>
                                                <Select
                                                    id="sr-bill-summary-type"
                                                    isDisabled={this.props.selectedLocation !== 'Summary'}
                                                    styles={singleStyles}
                                                    options={this.props.billSummaryList}
                                                    value={this.props.billSummaryList.find(ver => ver.value === this.props.selectedSummaryVersion)}
                                                    onChange={opt => this.props.handleSelectChange(opt, 'selectedSummaryVersion')} />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            }
                            <div style={{ cursor: 'pointer' }} onClick={() => this.props.toggleGroup("statusFieldset")} className=" advanced-search-fieldset-header">
                                <span className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Legislation Status</span>
                                <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip("status") }} className="button info">Info</a>
                                {this.state.showTooltip["status"] &&
                                    <div className="info-box search-tooltip">
                                        <p>Status - Where the bill is in the legislative process.</p>
                                        <p>If you do not input dates or check currently, the search will be executed for that session.</p>
                                    </div>
                                }
                                <button aria-expanded={this.props.statusFieldset} type="button" className={this.props.statusFieldset ? "arrow-up" : "arrow-down"}></button>
                            </div>
                            {this.props.statusFieldset &&
                                <fieldset>
                                    <div>
                                        <div className="inner-grid one-and-two two-mobile">
                                            <label htmlFor="se-bill-status-select">Status</label><div className="flex-row flex-vertical-center flex-end">
                                                <div className="toggle-switch" disabled={this.props.billStatusCategoryList.find(status => status.LegislationCategoryID === this.props.selectedBillStatusCategory)?.Name === "Continued to next session"} onClick={() => this.props.handleCurrentStatusChange()}>
                                                    <input id="current-status-button" checked={this.props.currentStatus} type="checkbox" style={{ width: "auto" }} />
                                                    <span className="slider"></span>
                                                </div>
                                                <label htmlFor="current-status-button" className="checkbox-label no-background">{this.props.selectedSession && !this.props.selectedSession.IsActive ? 'Final Status' : 'Currently'}</label>
                                            </div>
                                        </div>
                                        <Select
                                            id="se-bill-status-select"
                                            isClearable
                                            key={this.props.selectedBillStatusCategory}
                                            styles={singleStyles}
                                            options={this.props.billStatusCategoryList}
                                            placeholder="All"
                                            onChange={(opt) => this.props.handleSelectChange(opt, 'selectedBillStatusCategory')}
                                            value={this.props.billStatusCategoryList.find(status => status.LegislationCategoryID === this.props.selectedBillStatusCategory)}
                                        />
                                    </div>
                                    <div className="inner-grid two two-mobile">
                                        <div>
                                            <label htmlFor="se-bill-status-start-date">Start Date</label>
                                            <DatePicker
                                                id="se-bill-status-start-date"
                                                selected={this.props.billStatusStartDate ? moment(this.props.billStatusStartDate) : null}
                                                onChange={(opt) => {
                                                    opt.startOf('day');
                                                    this.props.handleDateChange(opt, 'billStatusStartDate')
                                                }}
                                                disabled={!this.props.selectedBillStatusCategory || this.props.currentStatus}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="se-bill-status-end-date">End Date</label>
                                            <DatePicker
                                                id="se-bill-status-end-date"
                                                selected={this.props.billStatusEndDate ? moment(this.props.billStatusEndDate) : null}
                                                onChange={(opt) => {
                                                    opt.endOf('day');
                                                    this.props.handleDateChange(opt, 'billStatusEndDate')
                                                }}
                                                disabled={!this.props.selectedBillStatusCategory || this.props.currentStatus}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="se-selected-chamber">Originating Chamber</label>
                                        <Select
                                            styles={singleStyles}
                                            id="se-selected-chamber"
                                            options={Fields.originatingChamberOptions}
                                            onChange={this.props.chamberChange}
                                            value={this.props.selectedChamber}
                                            placeholder="Both"
                                            isClearable={true}
                                        />
                                    </div>
                                </fieldset>
                            }
                            <div style={{ cursor: 'pointer' }} onClick={() => this.props.toggleGroup("committeesFieldset")} className="advanced-search-fieldset-header">
                                <span className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Committees</span>
                                {/* <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip("committee") }} className="button info">Info</a>
                                {this.state.showTooltip["committee"] &&
                                    <div className="info-box search-tooltip">
                                        <p></p>
                                    </div>
                                } */}
                                <div />
                                <button aria-expanded={this.props.committeesFieldset} type="button" className={this.props.committeesFieldset ? "arrow-up" : "arrow-down"}></button>
                            </div>
                            {this.props.committeesFieldset &&
                                <fieldset>
                                    <div>
                                        <label htmlFor="se-selected-committee">Committees</label>
                                        <Select
                                            styles={singleStyles}
                                            isClearable
                                            key={this.props.selectedCommittee}
                                            id="se-selected-committee"
                                            options={this.props.committeeList}
                                            onChange={this.props.committeeChange}
                                            value={getSelectedCommitteeLabel(this.props.committeeList.flatMap(x => x.options || x).find(committee => committee.CommitteeID == this.props.selectedCommittee))}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="se-selected-subcommittee">Subcommittees</label>
                                        <Select
                                            styles={singleStyles}
                                            isClearable
                                            key={this.props.selectedSubcommittee}
                                            id="se-selected-subcommittee"
                                            isDisabled={!this.props.selectedCommittee}
                                            options={this.props.subcommitteeList}
                                            onChange={(opt) => this.props.handleSelectChange(opt, 'selectedSubcommittee')}
                                            value={this.props.subcommitteeList.find(subcommittee => subcommittee.CommitteeID === this.props.selectedSubcommittee)}
                                        />
                                    </div>
                                </fieldset>
                            }
                            <div style={{ cursor: 'pointer' }} onClick={() => this.props.toggleGroup("membersFieldset")} className="advanced-search-fieldset-header">
                                <span className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Members</span>
                                <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip("members") }} className="button info">Info</a>
                                {this.state.showTooltip["members"] &&
                                    <div className="info-box search-tooltip">
                                        <p>Chief Patron - The member(s) that originated the bill.</p>
                                        <p>Sponsor - The member(s) that originated an amendment</p>
                                    </div>
                                }
                                <button aria-expanded={this.props.membersFieldset} type="button" className={this.props.membersFieldset ? "arrow-up" : "arrow-down"}></button>
                            </div>
                            {this.props.membersFieldset &&
                                <fieldset>
                                    <div>
                                        <label htmlFor="se-selected-patron">Patrons</label>
                                        <Select
                                            styles={singleStyles}
                                            id="se-selected-patron"
                                            isClearable
                                            key={this.props.selectedPatron}
                                            options={this.props.patronList}
                                            placeholder="All"
                                            onChange={this.props.handlePatronChange}
                                            value={this.props.patronList.find(patron => patron.MemberID === this.props.selectedPatron)}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="se-selected-patron-type">Patron Type</label>
                                        <Select
                                            isClearable
                                            styles={singleStyles}
                                            key={this.props.selectedPatronType}
                                            id="se-selected-patron-type"
                                            isDisabled={!this.props.selectedPatron}
                                            value={this.props.patronRoles.filter(role => this.props.selectedPatronType.includes(role.PatronTypeID))}
                                            onChange={this.props.handlePatronTypeChange}
                                            options={this.props.patronList.find(x => x.MemberID === this.props.selectedPatron) ? this.props.patronList.find(x => x.MemberID === this.props.selectedPatron).ChamberCode === "S" ? this.props.patronRoles : this.props.patronRoles.filter(x => x.PatronTypeID !== 3) : []}
                                            isSearchable={false}
                                            isMulti={true}
                                        />
                                    </div>
                                </fieldset>
                            }
                            <div style={{ cursor: 'pointer' }} onClick={() => this.props.toggleGroup("subjectFieldset")} className="advanced-search-fieldset-header">
                                <span className={this.props.location.includes('bill-search') ? "txt-dark-blue" : ""}>Subject</span>
                                <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip("subject") }} className="button info">Info</a>
                                {this.state.showTooltip["subject"] &&
                                    <div className="info-box search-tooltip">
                                        <p>Bills listed by subject.</p>
                                    </div>
                                }
                                <button aria-expanded={this.props.subjectFieldset} type="button" className={this.props.subjectFieldset ? "arrow-up" : "arrow-down"}></button>
                            </div>
                            {this.props.subjectFieldset &&
                                <fieldset>
                                    <div>
                                        <label htmlFor="se-selected-subject">Subjects</label>
                                        <Select
                                            styles={singleStyles}
                                            id="se-selected-subject"
                                            isClearable
                                            key={this.props.selectedSubject}
                                            options={this.props.subjectList}
                                            placeholder="All Subjects"
                                            onChange={(opt) => this.props.handleSelectChange(opt, 'selectedSubject')}
                                            value={this.props.subjectList.find(subject => subject.SubjectIndexID === this.props.selectedSubject)}
                                        />
                                    </div>
                                </fieldset>
                            }
                            <div style={{ padding: '10px' }} className="center flex-row">
                                <button id="se-clear-form" className="button secondary" type="button" onClick={() => this.props.clearForm(true, true)}>
                                    Clear
                                </button>
                                <button id="se-submit-form" className="button" type="button"
                                    disabled={
                                        (!this.props.selectedChamber && !this.props.selectedBillEvent && !this.props.selectedBillType && !this.props.selectedCommittee && !this.props.selectedSubcommittee && !this.props.selectedPatron && !this.props.selectedSubject && !this.props.selectedKeywords && !this.props.selectedBillNumbers && !this.props.selectedBillRangeBeginning && !this.props.selectedBillRangeEnd && !this.props.selectedSubject && !this.props.selectedChapterNumber && !this.props.selectedBillStatusCategory)
                                        || this.props.isFetching || this.props.failedToGetSessions}
                                    title={!this.props.selectedChamber && !this.props.selectedBillEvent && !this.props.selectedBillType && !this.props.selectedCommittee && !this.props.selectedSubcommittee && !this.props.selectedPatron && !this.props.selectedSubject && !this.props.selectedKeywords && !this.props.selectedBillNumbers && !this.props.selectedBillRangeBeginning && !this.props.selectedBillRangeEnd && !this.props.selectedSubject && !this.props.selectedChapterNumber && !this.props.selectedBillStatusCategory && 'Please provide a parameter upon which to search.'}
                                    onClick={() => this.handleSubmit()}>
                                    Search
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}


export default AdvancedSearchBoxComponent;
